import React, {useEffect, useState} from 'react';
import firebase from 'gatsby-plugin-firebase';
import 'firebase/firestore';
import { HomePage } from '../containers/HomePage/HomePage';

const Home = () => {

  const [res, setRes] = useState([]);
  const docRef = firebase.firestore().collection('resources');
  
  // const docRefUsers = firebase.firestore().collection('users');

  // function arrayToCSV(objArray) {
  // const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  // let str = `${Object.keys(array[0])?.map(value => `"${value}"`)?.join(",")}` + '\r\n';

  //   return array.reduce((str, next) => {
  //     str += `${Object.values(next).map(value => `"${value}"`).join(",")}` + '\r\n';
  //     return str;
  //   }, str);
  // }
  
  
  useEffect(() => { 
    //get ressources
    docRef
      .get()
      .then((collection) => {
        const docres = [];

        collection.forEach(doc => {

          docres.push(doc.data());

        });
        setRes(docres);
      });
    
    // docRefUsers.where("createdAt", ">=", new Date("2024,12,30"))
    //   .where("createdAt", "<", new Date("2025,03,21")).get().then((collection) => {
    //   const docresusers = [];
        
    //   console.log(collection)

    //   collection.forEach(doc2 => {

    //     console.log(doc2)

    //     let doc = doc2.data()
    //     console.log(doc)

    //     if (doc.name) {

    //       let firstNameArr = doc.name.split(" ");

    //       let firstName = firstNameArr[0];

    //       const obj = {
    //         email: doc.email,
    //         name: doc.name,
    //         firstname: firstName,
    //       }

    //       docresusers.push(obj);
    //     }
      

    //   });

    //   console.log(arrayToCSV(docresusers));

      
    // });

    // docRefUsers.get().then((collection) => {
    //   const docresusers = [];

    //   console.log(collection)

    //   collection.forEach(doc2 => {

    //     console.log(doc2.id)

    //     let doc = doc2.data()
    //     console.log(doc)

    //     if (doc.isActive) {

    //       docRefUsers.doc(doc2.id).update({
    //         currentPlan: "pro"
    //       })

    //       // let firstNameArr = doc.name.split(" ");

    //       // let firstName = firstNameArr[0];

    //       // const obj = {
    //       //   email: doc.email,
    //       //   name: doc.name,
    //       //   firstname: firstName,
    //       // }

    //       // docresusers.push(obj);
    //     } 
    //     else {
    //       docRefUsers.doc(doc2.id).update({
    //         currentPlan: "free"
    //       })
    //     }


    //   });


    // });

  }, []);

  return <HomePage res={res} />;
};

export default Home;
